import styles from '../assets/css/app.module.css';
import React from "react";

function Kontakt(){
    React.useEffect(()=>{

        document.body.classList.add(styles.contentpage);
    })
    React.useEffect(()=>{

        setContentPosition();
        function setContentPosition(){
            let element = refContent.current;

            let innerHeight = window.innerHeight;
            let baseheight = 740;
            let baseposition = 240;
            var dmedia = window.matchMedia('(min-width:1100px)');
            if(innerHeight > baseheight ){

                let calc = innerHeight-baseheight;
                let calcpos = baseposition + calc;

                element.style.top = calcpos +"px";

            }
            if (dmedia.matches) {
                if (innerHeight < baseheight) {

                    let calc = baseheight - innerHeight;
                    let calcpos = baseposition - calc;
                    element.style.top = calcpos + "px";
                }
            }

            window.addEventListener('resize',setContentPosition);

            return(()=>{
                window.removeEventListener('resize',setContentPosition);
            })



        }

    })

    const refContent = React.useRef();
    return(
        <section className={styles.normcontent} ref={refContent}>

        <h1>Kontakt</h1>
    <p>LMI GRUPPE<br/>
        Kantstraße 164<br/>
        10623 Berlin</p>



            <p>
    Telefon:  +49 30 300 149 3124
    <br/>
    E-Mail: <a href="mailto:mail@lmi-gruppe.de">mail@lmi-gruppe.de</a></p>






        </section>

    )
}
export default Kontakt;