import React, { useEffect, useState, useRef } from "react";
import styles from '../assets/css/app.module.css';

function MouseMover() {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const refMouseElement = useRef();

    useEffect(() => {
        const img = new Image();
        img.src = '../cursor/cursor-circle.svg'; // Pfade anpassen, falls nötig

        function handleMouseMove(e) {
            setPosition({ x: (e.clientX - 70), y: (e.clientY - 60) });
        }

        window.addEventListener('pointermove', handleMouseMove);

        return () => {
            window.removeEventListener('pointermove', handleMouseMove);
        };
    }, []);

    return (
        <div
            className={`${styles.mousemover} ${styles.backgroundImage}`}
            ref={refMouseElement}
            style={{
                 left:position.x,
                top:position.y
            }}
        ></div>
    );
}

export default MouseMover;
