import styles from '../assets/css/app.module.css';
import React from "react";
import {Link} from "react-router-dom";
import homelogo from "../assets/lmi-logo-leistungen.svg"
import Logo from "../assets/lmi-textlogo.svg";
function Header({location}){

    return(
        <>
            {
                location.pathname === "/"
                    ? (
                        <header id="header" className={styles.headerhome}>

                                <img className={styles.logo} src={homelogo} alt="LMI Gruppe"/>

                        </header>

                    )

                    : (


                        <header id="header" className={styles.header}>
                            <Link to="/">
                                <img className={styles.logo} src={Logo} alt="LMI Gruppe"/>

                            </Link>
                        </header>
                    )
            }

            </>
            )
}
export default Header;