import React from 'react';
import cssstyles from '../assets/css/app.module.css';
import tabimage from '../assets/bg/background-tablet.webp';
import deskimage from '../assets/bg/background-desktop.webp';
import mobileimage from '../assets/bg/background-mobile.webp';

function BackGroundSlider() {
    const [bgImage, setBGImage] = React.useState(deskimage);

    React.useEffect(() => {
        function initBackground() {
            const mobnmedia = window.matchMedia('(max-width:766px)');
            const tabmedia = window.matchMedia('(min-width:767px) and (max-width:1199px)');
            const desk = window.matchMedia('(min-width:1200px)');

            if (mobnmedia.matches) {
                setBGImage(mobileimage);
            } else if (tabmedia.matches) {
                setBGImage(tabimage);
            } else {
                setBGImage(deskimage);
            }
        }

        initBackground();
        window.addEventListener("resize", initBackground);

        // Corrected return statement: Remove the unnecessary brackets
        return () => {
            window.removeEventListener("resize", initBackground);
        };
    }, []);

    return (
        <section
            style={{ backgroundImage: `url(${bgImage})` }}
            className={cssstyles.backgroundimage}
        ></section>
    );
}

export default BackGroundSlider;
