import React from "react";
import masswerklogo from "../logos/masswerk.svg";
import masswerklogoh from "../logos/masswerk-hover.svg";
import lmiprojektlogo from "../logos/lmi-projekt-logo.svg";
import lmiprojekthover from "../logos/lmi-projekt-logo-hover.svg";
import ladenbaulog from "../logos/lmi-ladenbau.svg";
import ladenbauhover from "../logos/ladenbau-hover.svg";
import {Link} from "react-router-dom";

import styles from '../assets/css/logoitems.module.css';

function Logoitems(){

    const [masslogo,setMasslog] = React.useState(masswerklogo)
    const [lmiprojekt,setLmiprojekt] = React.useState(lmiprojektlogo);
    const [ladenbau,setladenbau] = React.useState(ladenbaulog);

    const hoverMass = ()=>{

        setMasslog(masswerklogoh)
    }
    const outMass = ()=>{

        setMasslog(masswerklogo)
    }
    const hoverPro = ()=>{

        setLmiprojekt(lmiprojekthover)
    }
    const outPro = ()=>{

    setLmiprojekt(lmiprojektlogo)
    }
    const hoverLaden = ()=>{
    setladenbau(ladenbauhover)
    }
    const outLaden = ()=>{
    setladenbau(ladenbaulog)
    }


    React.useEffect(()=>{

        document.body.classList.add(styles.imagebody)
    })

    React.useEffect(()=>{

        setLogoPosition();
        function setLogoPosition() {
            let element = reflogoliste.current;
            var media = window.matchMedia('(max-width:766px)');
            let screenHeight = window.screen.height;
            let baseheight = 740;
            let baseposition = 62;
            let basInnerHeight=830;
            let innerHeight = window.innerHeight;
            var dmedia = window.matchMedia('(min-width:767px)');
            if (media.matches) {
                if (screenHeight > baseheight) {

                    let calc = screenHeight - baseheight;
                    let calcpos = baseposition - calc;

                    element.style.bottom = calcpos + "px";


                }
            }
            if (dmedia.matches) {
                if (innerHeight < basInnerHeight) {

                    let calc = basInnerHeight - innerHeight;
                    element.style.top = "-" + calc + "px";

                }
            }

        }
        window.addEventListener('resize',setLogoPosition);

return(()=>{
    window.removeEventListener('resize',setLogoPosition);
})





    })



   const reflogoliste = React.useRef();


    return(

       <section className={styles.logoframe}>
           <section className={styles.logowrapper} ref={reflogoliste}>
       <section className={`${styles.logoitem} ${styles.logomasswerk} `}>

        <Link to="https://www.masswerk.cc/" target="_blank">
        <img src={masslogo} alt="maßwerk design" onMouseOver={hoverMass} onMouseOut={outMass}/>
       </Link>
        </section>
       <section className={`${styles.logoitem} ${styles.logoprojekt} `}>
           <Link to="https://www.lmiprojekt.de/" target="_blank">

           <img src={lmiprojekt} alt="LMI Projekt" onMouseOver={hoverPro} onMouseOut={outPro}/>
           </Link>
       </section>
       <section className={`${styles.logoitem} ${styles.logoladenbau} `}>
           <Link to="https://www.dieladenmacher.de/" target="_blank">

           <img src={ladenbau} alt="LMI Ladenbau" onMouseOver={hoverLaden} onMouseOut={outLaden}/>
           </Link>
       </section>
           </section>
       </section>

    )
}
export default Logoitems;