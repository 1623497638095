import React from "react";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import MouseMover from "./components/MouseMover";
// Importieren Sie Ihre anderen Komponenten
import Impressum from "./components/Impressum";
import Datenschutz from "./components/Datenschutz";
import Kontakt from "./components/Kontakt";
import styles from "./assets/css/app.module.css";
import BackGroundSlider from "./components/BackGroundSlider";
import MainContent from "./components/MainContent";
import LandingPage from "./LandingPage";
import Navigation from "./components/Navigation";
import Header from "./components/Header";

function App() {


    return (
        <BrowserRouter>
            <AppContent />
        </BrowserRouter>
    );
}



function AppContent() {
    const [showSlider, setShowSlider] = React.useState(false);
    const location = useLocation();
    React.useEffect(()=>{
        if(location.pathname === "/") {
            document.body.classList.remove(styles.contentpage);
            document.body.classList.add(styles.landingpage);

        }
        else{
            document.body.classList.add(styles.landingpage);

        }
        },[location]);
    React.useEffect(() => {
        setShowSlider(location.pathname === "/");
    }, [location]);

    return (
        <>
            <MouseMover/>
            <section className={styles.backgroundsliderframe}>
                {showSlider && <BackGroundSlider />}
            </section>
            <section className={styles.maincontentframe}>

                <Header location={location} />
                <section className={styles.navigationframe}>
                    <Navigation />
                </section>
                <section className={styles.contentarea}>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/impressum" element={<Impressum />} />
                        <Route path="/datenschutz" element={<Datenschutz />} />
                        <Route path="/kontakt" element={<Kontakt />} />


                    </Routes>
                </section>
            </section>
        </>
    );
}

export default App;
