import styles from '../assets/css/app.module.css';
import React from "react";
import MouseMover from "./MouseMover";
function Impressum(){
React.useEffect(()=>{

    document.body.classList.add(styles.contentpage);
},[document.body.classList])
    const refContent = React.useRef();
    React.useEffect(()=>{

        setContentPosition();
        function setContentPosition(){
            let element = refContent.current;
            var media = window.matchMedia('(max-width:766px)');
            let screenHeight = window.screen.height;
            let baseheight = 740;
            let baseposition = 240;
            let innerHeight = window.innerHeight;
            var dmedia = window.matchMedia('(min-width:1100px)');
            if(innerHeight > baseheight ){

                let calc = innerHeight-baseheight;
                let calcpos = baseposition + calc;

                element.style.top = calcpos +"px";

            }
            if (dmedia.matches) {
                if (innerHeight < baseheight) {

                    let calc = baseheight - innerHeight;
                    let calcpos = baseposition - calc;
                    element.style.top = calcpos + "px";
                }
            }
            window.addEventListener('resize',setContentPosition);

            return(()=>{
                window.removeEventListener('resize',setContentPosition);
            })



        }

    },[refContent])





    return(
        <section className={styles.normcontent} ref={refContent}>

        <h1>Impressum</h1>
        <p>
           <strong>Angaben gemäß § 5 TMG:</strong><br/>
            LMI Projekt Immobilien GmbH<br/>
            Kantstraße 164<br/>
            10623 Berlin</p>
<p>
<strong>Vertreten durch:</strong><br/>
    Juri Ljubenow</p>
<p>
<strong>Kontakt:</strong><br/>
            Telefon: +49 30 300 149 3124<br/>
            E-Mail: mail@lmi-gruppe.de
</p>
            <p>
        <strong>Registereintrag: </strong><br/>
            Eintragung im Handelsregister.<br/>
            Registergericht: Amtsgericht Charlottenburg<br/>
            Registernummer: HRB 167995</p>
<p>
<strong> Umsatzsteuer-ID:</strong><br/>
            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
    DE 201 833 860</p>

        </section>
    )
}
export default Impressum;