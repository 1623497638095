import styles from '../assets/css/app.module.css';
import React from "react";
import {Link} from "react-router-dom";

function Navigation(){

    React.useEffect(()=> {

        setNavPosition();

        function setNavPosition() {
            let element = refNavigation.current;
            var media = window.matchMedia('(max-width:1300)');
            var dmedia = window.matchMedia('(min-width:767p)');

            let screenHeight = window.screen.height;
            let baseheight = 740;
            let baseposition = -10;
            let listpos = 165;
            let basInnerHeight = 876;
            let innerHeight = window.innerHeight;
            if (media.matches) {

                if (screenHeight > baseheight) {

                    let calc = screenHeight - baseheight;
                    let calcpos = baseposition + calc - 100;

                    element.style.bottom = calcpos + "px";


                }
            }
            if (dmedia.matches) {
                if (innerHeight < basInnerHeight) {

                    let calc = (basInnerHeight - innerHeight) + listpos;
                    element.style.bottom = calc  + "px";

                }
                window.addEventListener('resize', setNavPosition);

                return (() => {
                    window.removeEventListener('resize', setNavPosition);
                })

            }
        }
    });
    const refNavigation = React.useRef();




    return(

        <ul ref={refNavigation} className={styles.navilist}>
            <li><Link to="/kontakt">Kontakt</Link></li>
            <li><Link to="/impressum">Impressum</Link></li>
            <li><Link to="/datenschutz">Datenschutz</Link></li>

        </ul>
    )
}
export default Navigation;