import React from "react";

import styles from './assets/css/app.module.css';

import ListItems from "./components/ListItems";
import Logoitems from "./components/Logoitems";


function LandingPage() {
return(
<>

<Logoitems/>





    </>
  );
}

export default LandingPage;
